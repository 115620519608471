import React from "react";
import BusinessHours from "./sub-components/BusinessHours";
import ContactMap from "./sub-components/ContactMap";
import ContactPhone from "./sub-components/ContactPhone";
import HomeLogo from "./sub-components/HomeLogo";
import Footer from "./Footer";

const BusinessInfo = () => {
	return (
		<div className="bg-light border-top border-3 border-dark p-4">
			<div className="d-flex flex-wrap justify-content-center align-items-center">
				<div className="mx-2">
					<HomeLogo />
				</div>
				<div className="mx-2">
					<BusinessHours />
				</div>
				<div className="mx-2">
					<ContactMap />
				</div>
				<div className="mx-2">
					<ContactPhone />
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default BusinessInfo;
