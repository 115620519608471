import React from "react";
import { Helmet } from "react-helmet";

const Suspension = () => {
	return (
		<>
			<Helmet>
				<meta
					name="description"
					content="T&B Automotive in Lindsay, Ontario offers comprehensive auto suspension inspections. Our skilled technicians thoroughly inspect your vehicle's suspension system to ensure optimal performance and safety. Schedule your suspension inspection today!"
				/>
				<title>
					Auto Suspension Inspections in Lindsay, Ontario | T&B Automotive
				</title>
			</Helmet>
			<section className="page-section bg-light" id="service">
				<div className="container">
					<div className="text-center">
						<h1 className="section-heading text-uppercase">
							Auto Suspension Inspections in Lindsay, Ontario
						</h1>
						<p className="service-caption">
							Welcome to T&B Automotive, your trusted auto service provider in
							Lindsay, Ontario. We specialize in professional auto suspension
							inspections to keep your vehicle running smoothly and safely. Our
							experienced technicians utilize advanced diagnostic equipment to
							thoroughly examine your suspension system, identifying any
							potential issues and providing the necessary repairs. Trust us to
							ensure optimal performance and enhance your driving experience.
							Schedule your suspension inspection with T&B Automotive today!
						</p>
						<h2 className="mb-5">
							<strong>
								Why Choose T&B Automotive for Suspension Inspections?
							</strong>
						</h2>
						<div
							className="accordion col-md-6 mx-auto mb-3"
							id="serviceAccordion"
						>
							{/* Item #1 */}
							<div className="accordion-item">
								<h3 className="accordion-header">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseOne"
										aria-expanded="true"
										aria-controls="collapseOne"
									>
										Expertise
									</button>
								</h3>
								<div
									id="collapseOne"
									class="accordion-collapse collapse show"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										At T&B Automotive, we have a team of skilled and certified
										technicians with extensive knowledge of various suspension
										systems. You can rely on their expertise to accurately
										diagnose and address any suspension-related concerns.
									</div>
								</div>
							</div>
							{/* Item #2 */}
							<div class="accordion-item">
								<h3 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseTwo"
										aria-expanded="false"
										aria-controls="collapseTwo"
									>
										Cutting-Edge Equipment
									</button>
								</h3>
								<div
									id="collapseTwo"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We invest in state-of-the-art diagnostic tools and equipment
										to perform thorough suspension inspections. Our advanced
										technology allows us to identify even the slightest
										abnormalities in your suspension system, ensuring a precise
										diagnosis.
									</div>
								</div>
							</div>
							{/* Item #3 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseThree"
										aria-expanded="false"
										aria-controls="collapseThree"
									>
										Comprehensive Inspection Process
									</button>
								</h2>
								<div
									id="collapseThree"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										Our suspension inspections are comprehensive, covering all
										vital components such as shocks, struts, springs, control
										arms, bushings, and more. We meticulously assess each part
										to detect signs of wear, damage, or misalignment.
									</div>
								</div>
							</div>
							{/* Item #4 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFour"
										aria-expanded="false"
										aria-controls="collapseFour"
									>
										Safety and Performance
									</button>
								</h2>
								<div
									id="collapseFour"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										A well-maintained suspension system contributes to the
										overall safety and performance of your vehicle. By choosing
										T&B Automotive for your suspension inspection, you can drive
										with confidence, knowing that your car's handling,
										stability, and braking capabilities are optimized.
									</div>
								</div>
							</div>
							{/* Item #5 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFive"
										aria-expanded="false"
										aria-controls="collapseFive"
									>
										Transparent and Honest Service
									</button>
								</h2>
								<div
									id="collapseFive"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We believe in transparency and prioritize honest
										communication with our customers. After completing the
										inspection, we will provide you with a detailed report of
										our findings, along with recommendations for any necessary
										repairs or adjustments.
									</div>
								</div>
							</div>
						</div>
						<h4 className="mt-4">Schedule Your Suspension Inspection Today!</h4>
						<div className="service-caption">
							<p>
								Don't neglect the health of your suspension system. Ensure a
								comfortable and safe ride by scheduling a suspension inspection
								with T&B Automotive. Our dedicated team is ready to assist you
								and answer any questions you may have.
							</p>
							<p className="col-6 mx-auto p-3 border-start border-5 border-primary">
								<strong>
									Contact us today at{" "}
									<a href="tel:17053283635" className="">
										(705) 328-3635
									</a>{" "}
									or use our convenient{" "}
									<a href="https://tandbauto.ca/contact-us">online form</a> to
									secure your appointment. Trust T&B Automotive for top-notch
									suspension inspections in Lindsay, Ontario!
								</strong>
							</p>
							<p className="fs-5">
								Remember, regular suspension inspections are crucial for
								maintaining your vehicle's performance and safety. Let T&B
								Automotive be your go-to destination for all your automotive
								needs.
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Suspension;
