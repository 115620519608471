import React from "react";

const ContactPhone = () => {
	return (
		<div className="container">
			<div className="text-center">
				<h5>Call Us</h5>
				<a href="tel:+17053283635">
					<button className="btn btn-xl btn-primary">
						Call Now{" "}
						<span>
							<i className="bi bi-telephone-fill"></i>
						</span>
					</button>
				</a>
				<p className="my-2 fw-bold">(705) 328-3635</p>
			</div>
		</div>
	);
};

export default ContactPhone;
