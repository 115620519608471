import React from "react";
import { pages } from "./pages";

const NavItem = () => {
	const currentPage = [];

	for (let i = 0; i < pages.length; i++) {
		const { name, link } = pages[i];

		const navItem = (
			<li className="nav-item mx-1" key={name}>
				<a href={link}>
					<h3 className="mx-2">{name}</h3>
				</a>
			</li>
		);

		currentPage.push(navItem);
	}

	return (
		<div
			className="navbar-collapse justify-content-end collapse"
			id="navbarNav"
		>
			<ul className="navbar-nav">{currentPage}</ul>
		</div>
	);
};

export default NavItem;
