// import third party libraries
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";
import { Helmet } from "react-helmet";

// import Pages
import Homepage from "./pages/HomePage";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import ContactUs from "./pages/ContactUs";

// import Components
import Navigation from "./pages/components/Navigation";
// import UnderConstruction from "./pages/UnderConstruction";

// import service pages
import TireChange from "./pages/services/TireChanges";
import OilChange from "./pages/services/OilChanges";
import AirConditioning from "./pages/services/AirConditioning";
import Alignment from "./pages/services/Alignment";
import Brakes from "./pages/services/Brakes";
import ComputerDiagnostics from "./pages/services/ComputerDiagnostic";
import Exhaust from "./pages/services/Exhaust";
import GeneralService from "./pages/services/GeneralService";
import SafetyInspections from "./pages/services/SafetyInspections";
import RustProofing from "./pages/services/RustProofing";
import Suspension from "./pages/services/Suspension";
import BusinessInfo from "./pages/components/BusinessInfo";

function App() {
	return (
		<main style={{ overflow: "hidden" }}>
			<Helmet>
				<title>Auto Repair Done Right | T&B Automotive</title>
				<meta
					name="description"
					content="Auto Repair Done Right | T&B Automotive"
				/>
			</Helmet>
			<Router>
				<Analytics />
				<Navigation />
				<Routes>
					<Route path="/" element={<Homepage />} />
					<Route path="/about-us" element={<AboutUs />} />
					<Route path="/services" element={<Services />} />
					<Route path="/services/tire-changes" element={<TireChange />} />
					<Route path="/services/oil-changes" element={<OilChange />} />
					<Route path="/services/brake-inspections" element={<Brakes />} />
					<Route
						path="/services/safety-inspections"
						element={<SafetyInspections />}
					/>
					<Route path="/services/exhaust-repairs" element={<Exhaust />} />
					<Route
						path="/services/air-conditioning"
						element={<AirConditioning />}
					/>
					<Route
						path="/services/suspension-inspection"
						element={<Suspension />}
					/>
					<Route
						path="/services/general-repairs"
						element={<GeneralService />}
					/>
					<Route path="/services/alignment" element={<Alignment />} />
					<Route
						path="/services/computer-diagnostics"
						element={<ComputerDiagnostics />}
					/>
					<Route path="/services/rust-proofing" element={<RustProofing />} />
					<Route path="/contact-us" element={<ContactUs />} />
				</Routes>
				<BusinessInfo />
			</Router>
		</main>
	);
}

export default App;
