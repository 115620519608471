import React from "react";
import { Helmet } from "react-helmet";

const SafetyInspections = () => {
	return (
		<>
			<Helmet>
				<meta
					name="description"
					content="Ensure your vehicle's safety with professional auto safety inspections in Lindsay, Ontario. Trust T&B Automotive for thorough inspections and peace of mind. Contact us today!"
				/>
				<title>
					Auto Safety Inspections in Lindsay, Ontario | T&B Automotive
				</title>
			</Helmet>
			<section className="page-section bg-light" id="service">
				<div className="container">
					<div className="text-center">
						<h1 className="section-heading text-uppercase">
							Reliable Auto Safety Inspections in Lindsay, Ontario
						</h1>
						<p className="service-caption">
							At T&B Automotive in Lindsay, Ontario, we understand the
							importance of keeping your vehicle safe on the road. Our
							experienced team specializes in comprehensive auto safety
							inspections to ensure your vehicle meets all necessary safety
							standards. Whether you're a responsible driver or getting ready to
							sell your car, our thorough inspections will provide you with
							peace of mind.
						</p>
						<h2 className="p-3 border-bottom border-primary border-3">
							Comprehensive Auto Safety Inspections
						</h2>
						<h3>
							<strong>Why Choose T&B Automotive?</strong>
						</h3>
						<p className="service-caption">
							When it comes to auto safety inspections, choosing a reliable and
							professional service provider is essential. At T&B Automotive, we
							pride ourselves on delivering top-quality inspections that cover
							all aspects of your vehicle's safety. Here's why you should choose
							us:
						</p>
						<div
							className="accordion col-md-6 mx-auto mb-3"
							id="serviceAccordion"
						>
							{/* Item #1 */}
							<div className="accordion-item">
								<h3 className="accordion-header">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseOne"
										aria-expanded="true"
										aria-controls="collapseOne"
									>
										Experienced Technicians
									</button>
								</h3>
								<div
									id="collapseOne"
									class="accordion-collapse collapse show"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										Our certified technicians have extensive experience in
										conducting auto safety inspections. They are well-versed in
										the latest safety standards and will thoroughly inspect your
										vehicle for any potential issues.
									</div>
								</div>
							</div>
							{/* Item #2 */}
							<div class="accordion-item">
								<h3 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseTwo"
										aria-expanded="false"
										aria-controls="collapseTwo"
									>
										State-of-the-Art Equipment
									</button>
								</h3>
								<div
									id="collapseTwo"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We utilize advanced diagnostic equipment and tools to
										perform precise and accurate inspections. This ensures that
										no safety concern goes unnoticed during the process.
									</div>
								</div>
							</div>
							{/* Item #3 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseThree"
										aria-expanded="false"
										aria-controls="collapseThree"
									>
										Comprehensive Inspection Checklist
									</button>
								</h2>
								<div
									id="collapseThree"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										Our inspection checklist is designed to cover all critical
										safety areas of your vehicle. From brakes and tires to
										lights and electrical systems, we leave no stone unturned to
										ensure your safety.
									</div>
								</div>
							</div>
							{/* Item #4 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFour"
										aria-expanded="false"
										aria-controls="collapseFour"
									>
										Detailed Inspection Reports
									</button>
								</h2>
								<div
									id="collapseFour"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										After conducting the inspection, we provide you with a
										detailed report outlining the findings. Our technicians will
										explain any concerns and recommend necessary repairs or
										maintenance to address safety issues.
									</div>
								</div>
							</div>
						</div>
						<h4 className="mt-4">Schedule Your Auto Safety Inspection Today</h4>
						<div className="service-caption">
							<p className="col-6 mx-auto p-3 border-start border-5 border-primary">
								<strong>
									Contact us today at{" "}
									<a href="tel:17053283635" className="">
										(705) 328-3635
									</a>{" "}
									or <a href="mailto: info@tandbauto.ca">info@tandbauto.ca</a>{" "}
									to schedule an appointment or inquire about our services. Our
									friendly team is ready to assist you.
								</strong>
							</p>
							<p className="fs-5">
								{" "}
								Don't compromise on your safety or the safety of your loved
								ones. Trust T&B Automotive in Lindsay, Ontario, for reliable and
								thorough auto safety inspections. Our team is dedicated to
								ensuring your vehicle meets the highest safety standards.
								Schedule your inspection today by contacting us at{" "}
								<a href="tel:17053283635" className="">
									(705) 328-3635
								</a>{" "}
								or filling out our convenient{" "}
								<a href="https://tandbauto.ca/contact-us">online form</a>.
							</p>
							<hr />
							<p>
								At T&B Automotive, we prioritize your safety. Our comprehensive
								auto safety inspections in Lindsay, Ontario, are designed to
								give you peace of mind on the road. Choose us for reliable
								service, experienced technicians, and detailed inspection
								reports. Contact us today to schedule your appointment!
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default SafetyInspections;
