import React from "react";
import { Helmet } from "react-helmet";

const OilChange = () => {
	return (
		<>
			<Helmet>
				<meta
					name="description"
					content="Looking for professional oil changes in Lindsay, Ontario? T&B
					Automotive offers expert oil change services to ensure your vehicle's
					safety and performance. Contact us today!"
				/>
				<title>Oil Changes in Lindsay, Ontario | T&B Automotive</title>
			</Helmet>
			<section className="page-section bg-light" id="service">
				<div className="container">
					<div className="text-center">
						<h1 className="section-heading text-uppercase">
							Expert Oil Changes in Lindsay, Ontario
						</h1>
						<p className="service-caption">
							Welcome to T&B Automotive, your trusted auto service provider in
							Lindsay, Ontario. We specialize in providing high-quality oil
							changes for all makes and models of vehicles. With our expertise
							and commitment to customer satisfaction, we ensure that your car's
							engine remains in optimal condition.
						</p>
						<h2 className="mb-5">
							<strong>Why Choose T&B Automotive for Your Oil Changes?</strong>
						</h2>
						<div
							className="accordion col-md-6 mx-auto mb-3"
							id="serviceAccordion"
						>
							{/* Item #1 */}
							<div className="accordion-item">
								<h3 className="accordion-header">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseOne"
										aria-expanded="true"
										aria-controls="collapseOne"
									>
										Expert Technicians
									</button>
								</h3>
								<div
									id="collapseOne"
									class="accordion-collapse collapse show"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										Our team consists of highly skilled and experienced
										technicians who have a deep understanding of various vehicle
										engines. They will perform your oil change with precision
										and care, ensuring that your car runs smoothly.
									</div>
								</div>
							</div>
							{/* Item #2 */}
							<div class="accordion-item">
								<h3 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseTwo"
										aria-expanded="false"
										aria-controls="collapseTwo"
									>
										Quality Products
									</button>
								</h3>
								<div
									id="collapseTwo"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										At T&B Automotive, we only use top-quality oils and filters
										that meet or exceed manufacturer specifications. We
										understand the importance of using the right products to
										enhance engine performance and prolong the life of your
										vehicle.
									</div>
								</div>
							</div>
							{/* Item #3 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseThree"
										aria-expanded="false"
										aria-controls="collapseThree"
									>
										Quick and Efficient Service
									</button>
								</h2>
								<div
									id="collapseThree"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We value your time and strive to complete oil changes
										promptly, without compromising on quality. Our streamlined
										process ensures that you can get back on the road quickly,
										with the peace of mind that your car has received proper
										care.
									</div>
								</div>
							</div>
							{/* Item #4 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFour"
										aria-expanded="false"
										aria-controls="collapseFour"
									>
										Preventative Maintenance
									</button>
								</h2>
								<div
									id="collapseFour"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										Regular oil changes are crucial for the overall health of
										your vehicle. By choosing T&B Automotive, you are taking a
										proactive step towards preventing costly engine repairs down
										the line. Our knowledgeable technicians can also provide
										recommendations for other essential maintenance services
										based on your vehicle's needs.
									</div>
								</div>
							</div>
							{/* Item #5 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFive"
										aria-expanded="false"
										aria-controls="collapseFive"
									>
										Competitive Prices
									</button>
								</h2>
								<div
									id="collapseFive"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We understand that affordability is important to our
										customers. That's why we offer competitive prices for our
										oil change services, without compromising on the quality of
										our work. Visit our website or contact us for current
										pricing information.
									</div>
								</div>
							</div>
						</div>
						<div className="service-caption">
							<p className="fs-4 fw-semibold">
								Schedule Your Oil Change Today!
							</p>
							<p className="col-6 mx-auto p-3 border-start border-5 border-primary">
								<strong>
									Contact us today at{" "}
									<a href="tel:17053283635" className="">
										(705) 328-3635
									</a>{" "}
									or <a href="mailto: info@tandbauto.ca">info@tandbauto.ca</a>{" "}
									to schedule an appointment or inquire about our oil change
									services.
								</strong>
							</p>
							<p className="fs-5">
								{" "}
								Don't wait until your engine starts showing signs of wear and
								tear. Visit T&B Automotive in Lindsay, Ontario, for professional
								oil change services that you can rely on. Our friendly staff is
								ready to assist you and answer any questions you may have. Trust
								us to keep your engine running smoothly!
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default OilChange;
