import React from "react";
import { services } from "./components/sub-components/services";
import ServiceCard from "./components/sub-components/ServiceCard";

const Services = () => {
	return (
		<section className="page-section bg-light" id="service">
			<div className="min-vh-100">
				<div className="container">
					<div className="text-center">
						<h2 className="section-heading text-uppercase">How Can We Help?</h2>
					</div>
					<div className="row" id="servicesContainer">
						{services.map((service) => (
							<ServiceCard
								key={service.name}
								name={service.name}
								href={service.href}
								image={service.image}
								price={service.price}
							/>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Services;
