import React from "react";

const ServiceButton = () => {
	return (
		<div className="text-center my-3">
			<a href="/services">
				<button className="btn btn-primary btn-xl text-uppercase">
					See More{" "}
					<span>
						<i className="bi bi-three-dots"></i>
					</span>
				</button>
			</a>
		</div>
	);
};

export default ServiceButton;
