// import images for service cards
export const services = [
  {
    name: "Tire Changes",
    price: 59.95,
    image: "",
    href: "services/tire-changes",
  },
  {
    name: "Oil Changes",
    price: 49.95,
    image: "",
    href: "services/oil-changes",
  },
  {
    name: "Brake Inspections",
    price: 59.95,
    image: "",
    href: "services/brake-inspections",
  },
  {
    name: "Safety Inspections",
    price: 119.95,
    image: "",
    href: "services/safety-inspections",
  },
  {
    name: "Exhaust Repairs",
    price: 149,
    image: "",
    href: "services/exhaust-repairs",
  },
  {
    name: "Air Conditioning Performance Test",
    price: 59.95,
    image: "",
    href: "services/air-conditioning",
  },
  {
    name: "Suspension Inspection",
    price: 59.95,
    image: "",
    href: "services/suspension-inspection",
  },
  {
    name: "General Repairs",
    price: 119.95,
    image: "",
    href: "services/general-repairs",
  },
  {
    name: "Alignment",
    price: 119.95,
    image: "",
    href: "services/alignment",
  },
  {
    name: "Computer Diagnostics",
    price: 119.95,
    image: "",
    href: "services/computer-diagnostics",
  },
  {
    name: "Rust Proofing",
    price: 139,
    image: "",
    href: "services/rust-proofing",
  },
];
