import React from "react";
import { Helmet } from "react-helmet";

const Alignment = () => {
	return (
		<>
			<Helmet>
				<meta
					name="description"
					content="Looking for reliable tire alignment services in Lindsay, Ontario? T&B Automotive offers expert wheel alignment solutions for improved vehicle performance and increased tire lifespan. Book an appointment today!"
				/>
				<title>
					Tire Alignment Services in Lindsay, Ontario | T&B Automotive
				</title>
			</Helmet>
			<section className="page-section bg-light" id="service">
				<div className="container">
					<div className="text-center">
						<h1 className="section-heading text-uppercase">
							Tire Alignment Services in Lindsay, Ontario
						</h1>
						<p className="service-caption">
							Welcome to T&B Automotive, your trusted source for professional
							tire alignment services in Lindsay, Ontario. We specialize in
							providing high-quality wheel alignment solutions that ensure your
							vehicle performs optimally while enhancing the lifespan of your
							tires. With our experienced technicians and advanced equipment, we
							deliver precise and accurate alignments for all makes and models.
							Drive with confidence knowing your vehicle's wheels are aligned to
							perfection.
						</p>
						<h2 className="mb-5">
							<strong>Why Choose T&B Automotive for Tire Alignment?</strong>
						</h2>
						<div
							className="accordion col-md-6 mx-auto mb-3"
							id="serviceAccordion"
						>
							{/* Item #1 */}
							<div className="accordion-item">
								<h3 className="accordion-header">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseOne"
										aria-expanded="true"
										aria-controls="collapseOne"
									>
										Industry Expertise
									</button>
								</h3>
								<div
									id="collapseOne"
									class="accordion-collapse collapse show"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										With years of experience in the automotive industry, T&B
										Automotive has built a solid reputation for delivering
										exceptional tire alignment services. Our skilled technicians
										stay updated with the latest alignment techniques and
										equipment to provide you with top-notch service.
									</div>
								</div>
							</div>
							{/* Item #2 */}
							<div class="accordion-item">
								<h3 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseTwo"
										aria-expanded="false"
										aria-controls="collapseTwo"
									>
										Advanced Alignment Equipment
									</button>
								</h3>
								<div
									id="collapseTwo"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										At T&B Automotive, we invest in state-of-the-art alignment
										equipment to ensure precise measurements and adjustments.
										Our cutting-edge technology allows us to identify
										misalignments quickly and make accurate corrections,
										resulting in a smoother and safer driving experience.
									</div>
								</div>
							</div>
							{/* Item #3 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseThree"
										aria-expanded="false"
										aria-controls="collapseThree"
									>
										Enhanced Vehicle Performance
									</button>
								</h2>
								<div
									id="collapseThree"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										Proper wheel alignment not only extends the lifespan of your
										tires but also improves your vehicle's overall performance.
										By aligning your wheels, you'll experience better handling,
										increased fuel efficiency, reduced tire wear, and improved
										safety on the road.
									</div>
								</div>
							</div>
							{/* Item #4 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFour"
										aria-expanded="false"
										aria-controls="collapseFour"
									>
										Personalized Approach
									</button>
								</h2>
								<div
									id="collapseFour"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We understand that each vehicle is unique, and our team at
										T&B Automotive takes a personalized approach to every tire
										alignment service. Our technicians carefully assess your
										vehicle's alignment needs, taking into consideration your
										driving habits and specific requirements, to deliver
										tailored solutions that meet your expectations.
									</div>
								</div>
							</div>
							{/* Item #5 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFive"
										aria-expanded="false"
										aria-controls="collapseFive"
									>
										Affordable and Transparent Pricing
									</button>
								</h2>
								<div
									id="collapseFive"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										T&B Automotive offers competitive and transparent pricing
										for all our tire alignment services. We believe in providing
										value for our customers, so you can trust that you'll
										receive fair and affordable rates without sacrificing
										quality.
									</div>
								</div>
							</div>
						</div>
						<div className="service-caption">
							<p className="fs-4 fw-semibold">
								Schedule a Tire Alignment Service Today!
							</p>
							<p className="fs-5">
								Ready to experience the benefits of a properly aligned vehicle?
								Schedule a tire alignment service with T&B Automotive today. Our
								friendly staff will accommodate your preferred date and time,
								ensuring a convenient and hassle-free appointment. Take the
								first step towards a smoother ride and longer-lasting tires by
								contacting us now.
							</p>
							<p className="col-6 mx-auto p-3 border-start border-5 border-primary">
								<strong>
									Contact us today at{" "}
									<a href="tel:17053283635" className="">
										(705) 328-3635
									</a>{" "}
									or <a href="mailto: info@tandbauto.ca">info@tandbauto.ca</a>{" "}
									to schedule an appointment or inquire about our tire alignment
									services.
								</strong>
							</p>
							<p className="fs-5">
								{" "}
								T&B Automotive is your go-to destination for tire alignment
								services in Lindsay, Ontario. With our expertise, advanced
								equipment, and commitment to customer satisfaction, we deliver
								exceptional wheel alignment solutions that keep you safe on the
								road. Contact us today to schedule an appointment and unlock the
								full potential of your vehicle's performance.
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Alignment;
