import React from "react";
import { Helmet } from "react-helmet";

const ComputerDiagnostics = () => {
	return (
		<>
			<Helmet>
				<meta
					name="description"
					content="Looking for professional automotive computer diagnostics in Lindsay, Ontario? T&B Automotive offers expert automotive computer diagnostics to ensure your vehicle's safety and performance. Contact us today!"
				/>
				<title>
					Automotive Computer Diagnostics in Lindsay, Ontario | T&B Automotive
				</title>
			</Helmet>
			<section className="page-section bg-light" id="service">
				<div className="container">
					<div className="text-center">
						<h1 className="section-heading text-uppercase">
							Automotive Computer Diagnostics in Lindsay, Ontario
						</h1>
						<p className="service-caption">
							Welcome to T&B Automotive, your trusted destination for
							professional automotive computer diagnostics in Lindsay, Ontario.
							Our experienced technicians utilize cutting-edge diagnostic tools
							and industry-leading software to accurately diagnose and resolve
							complex automotive issues. With a commitment to excellence and
							customer satisfaction, we are here to ensure your vehicle's
							optimal performance.
						</p>
						<div id="whyChoose" className="mb-4">
							<h2 className="mb-3">
								<strong>
									Why Choose T&B Automotive for Automotive Computer Diagnostics?
								</strong>
							</h2>
							<div
								className="accordion col-md-6 mx-auto mb-3"
								id="serviceAccordion1"
							>
								{/* Item #1 */}
								<div className="accordion-item">
									<h3 className="accordion-header">
										<button
											className="accordion-button"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseOne"
											aria-expanded="true"
											aria-controls="collapseOne"
										>
											Expert Technicians
										</button>
									</h3>
									<div
										id="collapseOne"
										class="accordion-collapse collapse show"
										data-bs-parent="#serviceAccordion1"
									>
										<div class="accordion-body">
											At T&B Automotive, we have a team of highly skilled
											technicians who specialize in automotive computer
											diagnostics. With years of experience and extensive
											knowledge, our experts can quickly identify and diagnose
											any computer-related issues affecting your vehicle.
										</div>
									</div>
								</div>
								{/* Item #2 */}
								<div class="accordion-item">
									<h3 class="accordion-header">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseTwo"
											aria-expanded="false"
											aria-controls="collapseTwo"
										>
											State-of-the-Art Equipment
										</button>
									</h3>
									<div
										id="collapseTwo"
										class="accordion-collapse collapse"
										data-bs-parent="#serviceAccordion1"
									>
										<div class="accordion-body">
											We understand the importance of using advanced diagnostic
											equipment to accurately assess your vehicle's condition.
											Our workshop is equipped with cutting-edge tools and
											software, allowing us to access your vehicle's computer
											system, retrieve error codes, and pinpoint the root cause
											of any problems.
										</div>
									</div>
								</div>
								{/* Item #3 */}
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseThree"
											aria-expanded="false"
											aria-controls="collapseThree"
										>
											Comprehensive Diagnostics
										</button>
									</h2>
									<div
										id="collapseThree"
										class="accordion-collapse collapse"
										data-bs-parent="#serviceAccordion1"
									>
										<div class="accordion-body">
											Our automotive computer diagnostics service covers a wide
											range of vehicle makes and models. Whether you drive a
											car, truck, SUV, or hybrid, we have the expertise to
											diagnose and repair electronic and computer-related issues
											effectively.
										</div>
									</div>
								</div>
								{/* Item #4 */}
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseFour"
											aria-expanded="false"
											aria-controls="collapseFour"
										>
											Efficient Problem Resolution
										</button>
									</h2>
									<div
										id="collapseFour"
										class="accordion-collapse collapse"
										data-bs-parent="#serviceAccordion1"
									>
										<div class="accordion-body">
											Once we identify the underlying issue, our skilled
											technicians work diligently to provide a prompt and
											efficient solution. We prioritize transparency, ensuring
											that you are informed of the problem, the required
											repairs, and the associated costs before any work begins.
										</div>
									</div>
								</div>
								{/* Item #5 */}
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseFive"
											aria-expanded="false"
											aria-controls="collapseFive"
										>
											Customer Satisfaction
										</button>
									</h2>
									<div
										id="collapseFive"
										class="accordion-collapse collapse"
										data-bs-parent="#serviceAccordion1"
									>
										<div class="accordion-body">
											At T&B Automotive, customer satisfaction is our top
											priority. We strive to deliver exceptional service and
											build long-lasting relationships with our clients. Our
											friendly staff is always ready to address your concerns,
											answer your questions, and provide you with the necessary
											information to make informed decisions regarding your
											vehicle's diagnostics and repairs.
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr />
						<div id="serviceProcess" className="mb-4">
							<h2 className="mb-3">
								<strong>
									Our Automotive Computer Diagnostics Services Include:
								</strong>
							</h2>
							<div
								className="accordion col-md-6 mx-auto mb-3"
								id="serviceAccordion2"
							>
								{/* Item #1 */}
								<div className="accordion-item">
									<h3 className="accordion-header">
										<button
											className="accordion-button"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseTwoOne"
											aria-expanded="true"
											aria-controls="collapseTwoOne"
										>
											Check Engine Light Diagnosis
										</button>
									</h3>
									<div
										id="collapseTwoOne"
										class="accordion-collapse collapse show"
										data-bs-parent="#serviceAccordion2"
									>
										<div class="accordion-body">
											Is your check engine light illuminated? Our technicians
											can perform a comprehensive check engine light diagnosis
											to identify the underlying issue accurately. We'll
											retrieve the error codes from your vehicle's computer
											system and provide you with a detailed explanation of the
											problem.
										</div>
									</div>
								</div>
								{/* Item #2 */}
								<div class="accordion-item">
									<h3 class="accordion-header">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseTwoTwo"
											aria-expanded="false"
											aria-controls="collapseTwoTwo"
										>
											Electrical System Diagnostics
										</button>
									</h3>
									<div
										id="collapseTwoTwo"
										class="accordion-collapse collapse"
										data-bs-parent="#serviceAccordion2"
									>
										<div class="accordion-body">
											If you are experiencing electrical problems such as faulty
											wiring, battery issues, or malfunctioning sensors, our
											experts can perform thorough electrical system
											diagnostics. We use specialized tools and techniques to
											locate and resolve electrical issues promptly.
										</div>
									</div>
								</div>
								{/* Item #3 */}
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseTwoThree"
											aria-expanded="false"
											aria-controls="collapseTwoThree"
										>
											Engine Performance Analysis
										</button>
									</h2>
									<div
										id="collapseTwoThree"
										class="accordion-collapse collapse"
										data-bs-parent="#serviceAccordion2"
									>
										<div class="accordion-body">
											Are you noticing a decrease in your vehicle's performance
											or unusual noises? Our team can conduct an in-depth engine
											performance analysis to identify any performance-related
											issues. We'll assess fuel efficiency, ignition system
											performance, emission levels, and more to ensure optimal
											engine performance.
										</div>
									</div>
								</div>
								{/* Item #4 */}
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseTwoFour"
											aria-expanded="false"
											aria-controls="collapseTwoFour"
										>
											Transmission System Diagnostics
										</button>
									</h2>
									<div
										id="collapseTwoFour"
										class="accordion-collapse collapse"
										data-bs-parent="#serviceAccordion2"
									>
										<div class="accordion-body">
											For any transmission-related problems, our technicians can
											perform comprehensive diagnostics to determine the root
											cause. Whether it's a slipping transmission, rough
											shifting, or unusual noises, we'll diagnose the issue and
											recommend the necessary repairs or adjustments.
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="service-caption">
							<p className="col-6 mx-auto p-3 border-start border-5 border-primary">
								<strong>
									Contact us today at{" "}
									<a href="tel:17053283635" className="">
										(705) 328-3635
									</a>{" "}
									or <a href="mailto: info@tandbauto.ca">info@tandbauto.ca</a>{" "}
									to schedule an appointment or inquire about our automotive
									computer diagnostics.
								</strong>
							</p>
							<p className="fs-5">
								{" "}
								When it comes to accurate automotive computer diagnostics in
								Lindsay, Ontario, trust the experts at T&B Automotive. We are
								committed to providing top-notch service, utilizing advanced
								technology, and ensuring your vehicle receives the care it
								deserves. Contact us today to schedule an appointment or to
								learn more about our services. Your satisfaction is our
								guarantee!
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ComputerDiagnostics;
