import React from "react";
import { Helmet } from "react-helmet";

const GeneralService = () => {
	return (
		<>
			<Helmet>
				<meta
					name="description"
					content="Looking for reliable and affordable automotive repair services in Lindsay, Ontario? T&B Automotive offers expert general repair solutions at just $119.95 per hour. Contact us now!"
				/>
				<title>
					Affordable Automotive Repair in Lindsay, Ontario | T&B Automotive
				</title>
			</Helmet>
			<section className="page-section bg-light" id="service">
				<div className="container">
					<div className="text-center">
						<h1 className="section-heading text-uppercase">
							Expert Automotive Repair Services in Lindsay, Ontario
						</h1>
						<h2 className="mt-4">
							Affordable Rates: General Repair for Only $119.95 per Hour
						</h2>
						<p className="service-caption">
							Welcome to T&B Automotive, your trusted destination for
							top-quality automotive repair services in Lindsay, Ontario. We are
							dedicated to providing reliable and efficient solutions to keep
							your vehicle running smoothly.
						</p>
						<hr />
						<p className="service-caption">
							At T&B Automotive, we understand the importance of affordability
							without compromising on quality. That's why we offer general
							repair services at an unbeatable rate of just $119.95 per hour.
							Our experienced technicians are skilled in diagnosing and fixing a
							wide range of automotive issues.
						</p>
						<h3>Comprehensive Automotive Repair Solutions</h3>
						<p className="service-caption border-bottom border-primary border-4">
							Whether you're facing engine troubles, electrical system
							malfunctions, brake issues, or any other automotive concerns, our
							team at T&B Automotive has got you covered. With our extensive
							knowledge and state-of-the-art equipment, we ensure precise and
							efficient repairs for all makes and models.
						</p>
						<h4>
							<strong>Why Choose T&B Automotive?</strong>
						</h4>
						<p className="service-caption">
							When you choose T&B Automotive for your automotive repair needs in
							Lindsay, Ontario, you can expect:
						</p>
						<div
							className="accordion col-md-6 mx-auto mb-3"
							id="serviceAccordion"
						>
							{/* Item #1 */}
							<div className="accordion-item">
								<h3 className="accordion-header">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseOne"
										aria-expanded="true"
										aria-controls="collapseOne"
									>
										Expertise
									</button>
								</h3>
								<div
									id="collapseOne"
									class="accordion-collapse collapse show"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										Our team of certified technicians possesses in-depth
										knowledge and expertise in all aspects of automotive repair.
									</div>
								</div>
							</div>
							{/* Item #2 */}
							<div class="accordion-item">
								<h3 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseTwo"
										aria-expanded="false"
										aria-controls="collapseTwo"
									>
										Transparent Pricing
									</button>
								</h3>
								<div
									id="collapseTwo"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We believe in honesty and transparency. Our $119.95 per hour
										rate means you'll never have to worry about unexpected costs
										or hidden fees.
									</div>
								</div>
							</div>
							{/* Item #3 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseThree"
										aria-expanded="false"
										aria-controls="collapseThree"
									>
										Timely Service
									</button>
								</h2>
								<div
									id="collapseThree"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We value your time and strive to complete repairs promptly
										without compromising on quality.
									</div>
								</div>
							</div>
							{/* Item #4 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFour"
										aria-expanded="false"
										aria-controls="collapseFour"
									>
										Customer Satisfaction
									</button>
								</h2>
								<div
									id="collapseFour"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We prioritize customer satisfaction and go the extra mile to
										ensure your complete peace of mind.
									</div>
								</div>
							</div>
						</div>
						<div className="service-caption">
							<p className="col-6 mx-auto p-3 border-start border-5 border-primary">
								<strong>
									Contact us today at{" "}
									<a href="tel:17053283635" className="">
										(705) 328-3635
									</a>{" "}
									or <a href="mailto: info@tandbauto.ca">info@tandbauto.ca</a>{" "}
									to schedule an appointment or inquire about our services. Our
									friendly team is ready to assist you.
								</strong>
							</p>
							<p className="fs-5">
								{" "}
								Don't let automotive issues disrupt your daily routine. Trust
								T&B Automotive to deliver exceptional repair services at an
								affordable price.
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default GeneralService;
