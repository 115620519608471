import React from "react";
import { Helmet } from "react-helmet";

const AirConditioning = () => {
	return (
		<>
			<Helmet>
				<meta
					name="description"
					content="Looking for professional auto air conditioning performance tests in Lindsay, Ontario? T&B Automotive offers expert services to ensure your vehicle's AC system is running efficiently. Contact us today!"
				/>
				<title>
					Auto Air Conditioning Performance Tests in Lindsay, Ontario | T&B
					Automotive
				</title>
			</Helmet>
			<section className="page-section bg-light" id="service">
				<div className="container">
					<div className="text-center">
						<h1 className="section-heading text-uppercase">
							Auto Air Conditioning Performance Tests in Lindsay, Ontario
						</h1>
						<p className="service-caption">
							At T&B Automotive in Lindsay, Ontario, we specialize in providing
							top-notch auto air conditioning performance tests. Is your
							vehicle's AC system not cooling as it should? Don't sweat it! Our
							experienced technicians are here to diagnose and optimize your
							car's air conditioning performance. Trust us to keep you cool
							during hot Ontario summers. Contact T&B Automotive today for
							reliable and efficient AC testing services.
						</p>
						<h2 className="mb-5">
							<strong>
								Why Choose T&B Automotive for Auto Air Conditioning Performance
								Tests?
							</strong>
						</h2>
						<div
							className="accordion col-md-6 mx-auto mb-3"
							id="serviceAccordion"
						>
							{/* Item #1 */}
							<div className="accordion-item">
								<h3 className="accordion-header">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseOne"
										aria-expanded="true"
										aria-controls="collapseOne"
									>
										Expertise
									</button>
								</h3>
								<div
									id="collapseOne"
									class="accordion-collapse collapse show"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										Our skilled technicians possess extensive knowledge and
										expertise in diagnosing and testing auto air conditioning
										systems. With years of experience, they can identify and
										address any issues your AC may have.
									</div>
								</div>
							</div>
							{/* Item #2 */}
							<div class="accordion-item">
								<h3 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseTwo"
										aria-expanded="false"
										aria-controls="collapseTwo"
									>
										Advanced Equipment
									</button>
								</h3>
								<div
									id="collapseTwo"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										At T&B Automotive, we invest in state-of-the-art diagnostic
										tools and equipment to ensure accurate testing and
										evaluation of your vehicle's air conditioning performance.
										Our cutting-edge technology enables us to provide precise
										results and recommend appropriate solutions.
									</div>
								</div>
							</div>
							{/* Item #3 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseThree"
										aria-expanded="false"
										aria-controls="collapseThree"
									>
										Comprehensive Testing
									</button>
								</h2>
								<div
									id="collapseThree"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										Our thorough air conditioning performance tests cover all
										essential aspects, including refrigerant levels, compressor
										functionality, temperature output, and overall system
										efficiency. We leave no stone unturned to ensure your AC is
										operating optimally.
									</div>
								</div>
							</div>
							{/* Item #4 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFour"
										aria-expanded="false"
										aria-controls="collapseFour"
									>
										Quality Service
									</button>
								</h2>
								<div
									id="collapseFour"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We prioritize customer satisfaction and deliver high-quality
										services at affordable prices. Our dedicated team is
										committed to providing exceptional customer service,
										answering your questions, and addressing your concerns
										promptly.
									</div>
								</div>
							</div>
							{/* Item #5 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFive"
										aria-expanded="false"
										aria-controls="collapseFive"
									>
										Timely Repairs
									</button>
								</h2>
								<div
									id="collapseFive"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										If any issues are detected during the performance test, our
										technicians will provide you with detailed explanations and
										repair recommendations. We strive to complete repairs
										quickly, ensuring your AC system is up and running
										efficiently in no time.
									</div>
								</div>
							</div>
							{/* Item #6 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseSix"
										aria-expanded="false"
										aria-controls="collapseSix"
									>
										Locally Trusted
									</button>
								</h2>
								<div
									id="collapseSix"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										T&B Automotive has been a trusted name in Lindsay, Ontario
										for years. We have built a strong reputation for our
										reliable automotive services, including air conditioning
										performance tests. Join countless satisfied customers who
										have chosen us as their go-to destination for all their
										automotive needs.
									</div>
								</div>
							</div>
						</div>
						<div className="service-caption">
							<p className="col-6 mx-auto p-3 border-start border-5 border-primary">
								<strong>
									Contact us today at{" "}
									<a href="tel:17053283635" className="">
										(705) 328-3635
									</a>{" "}
									or <a href="mailto: info@tandbauto.ca">info@tandbauto.ca</a>{" "}
									to schedule an appointment or inquire about our air
									conditioning performance tests.
								</strong>
							</p>
							<p className="fs-5">
								{" "}
								When it comes to auto air conditioning performance tests in
								Lindsay, Ontario, T&B Automotive is your trusted partner. Don't
								let a faulty AC system ruin your driving experience. Contact us
								today to schedule an appointment or to learn more about our
								services. Let us keep you cool and comfortable on the roads of
								Lindsay, Ontario!
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default AirConditioning;
