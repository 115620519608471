import React from "react";
import { Helmet } from "react-helmet";

const RustProofing = () => {
	return (
		<>
			<Helmet>
				<meta
					name="description"
					content="T&B Automotive in Lindsay, Ontario offers top-notch rust proofing services to protect your vehicle from corrosion. Keep your car looking great and extend its lifespan with our expert rust protection solutions."
				/>
				<title>
					Rust Proofing Services in Lindsay, Ontario | T&B Automotive
				</title>
			</Helmet>
			<section className="page-section bg-light" id="service">
				<div className="container">
					<div className="text-center">
						<h1 className="section-heading text-uppercase">
							Expert Rust Proofing Services in Lindsay, Ontario
						</h1>
						<p className="service-caption">
							Welcome to T&B Automotive, your trusted destination for
							professional rust proofing services in Lindsay, Ontario. Our team
							of skilled technicians is dedicated to helping you preserve the
							appearance and structural integrity of your vehicle. With our
							high-quality rust protection solutions, we ensure that your car
							remains protected from the damaging effects of corrosion. Trust us
							to keep your vehicle looking its best and enjoy peace of mind on
							the road.
						</p>
						<div className="ps-3 border-start border-5 border-primary">
							<h2 className="mb-2 text-start">Why Rust Proofing Matters</h2>
							<p className="fs-5 text-start mb-4">
								Living in Lindsay, Ontario, with its harsh winter conditions and
								road salt usage, rust can be a significant concern for your
								vehicle. Rust not only affects your car's appearance but also
								compromises its structural integrity and resale value. Rust
								proofing is a proactive step towards preventing corrosion,
								extending the lifespan of your vehicle, and saving you from
								costly repairs down the line.
							</p>
						</div>
						<p className="service-caption">
							At T&B Automotive, we offer comprehensive rust proofing services
							tailored to meet your specific needs. Our skilled technicians
							utilize industry-leading techniques and products to provide
							effective and long-lasting protection against rust. We take pride
							in delivering meticulous and thorough rust proofing solutions,
							covering all vulnerable areas of your vehicle, including the
							undercarriage, wheel wells, and body panels.
						</p>
						<h3>
							<strong>Benefits of Choosing T&B Automotive:</strong>
						</h3>
						<div
							className="accordion col-md-6 mx-auto mb-3"
							id="serviceAccordion"
						>
							{/* Item #1 */}
							<div className="accordion-item">
								<h3 className="accordion-header">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseOne"
										aria-expanded="true"
										aria-controls="collapseOne"
									>
										Expertise
									</button>
								</h3>
								<div
									id="collapseOne"
									class="accordion-collapse collapse show"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										With years of experience in the automotive industry, our
										technicians have the knowledge and skills to deliver
										exceptional rust proofing services.
									</div>
								</div>
							</div>
							{/* Item #2 */}
							<div class="accordion-item">
								<h3 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseTwo"
										aria-expanded="false"
										aria-controls="collapseTwo"
									>
										Quality Products
									</button>
								</h3>
								<div
									id="collapseTwo"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We only use premium rust protection products that are proven
										to be effective in safeguarding your vehicle against
										corrosion.
									</div>
								</div>
							</div>
							{/* Item #3 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseThree"
										aria-expanded="false"
										aria-controls="collapseThree"
									>
										Personalized Approach
									</button>
								</h2>
								<div
									id="collapseThree"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We understand that every vehicle is unique. That's why we
										offer personalized rust proofing solutions tailored to your
										specific make and model.
									</div>
								</div>
							</div>
							{/* Item #4 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFour"
										aria-expanded="false"
										aria-controls="collapseFour"
									>
										Competitive Pricing
									</button>
								</h2>
								<div
									id="collapseFour"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										Our rust proofing services are competitively priced to
										ensure you receive great value for your investment.
									</div>
								</div>
							</div>
							{/* Item #5 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFive"
										aria-expanded="false"
										aria-controls="collapseFive"
									>
										Customer Satisfaction
									</button>
								</h2>
								<div
									id="collapseFive"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We prioritize customer satisfaction and strive to exceed
										your expectations by providing friendly service and
										exceptional results.
									</div>
								</div>
							</div>
						</div>
						<h4 className="mt-4">
							Protect Your Investment with T&B Automotive
						</h4>
						<div className="service-caption">
							<p className="col-6 mx-auto p-3 border-start border-5 border-primary">
								<strong>
									Contact us today at{" "}
									<a href="tel:17053283635" className="">
										(705) 328-3635
									</a>{" "}
									or <a href="mailto: info@tandbauto.ca">info@tandbauto.ca</a>{" "}
									to schedule an appointment or inquire about our services. Our
									friendly team is ready to assist you.
								</strong>
							</p>
							<p className="fs-5">
								{" "}
								Don't let rust compromise the beauty and longevity of your
								vehicle. Visit T&B Automotive in Lindsay, Ontario, today and let
								our skilled technicians protect your investment. Schedule an
								appointment for rust proofing services by calling us at{" "}
								<a href="tel:17053283635" className="">
									(705) 328-3635
								</a>{" "}
								or filling out our convenient{" "}
								<a href="https://tandbauto.ca/contact-us">online form</a>.
								Preserve your car's value and enjoy the peace of mind that comes
								with effective rust protection from T&B Automotive.
							</p>
							<hr />
							<p>
								T&B Automotive is a trusted automotive service provider located
								in Lindsay, Ontario. Along with rust proofing, we offer a wide
								range of services, including auto repairs, maintenance, and
								more. Contact us today at{" "}
								<a href="tel:17053283635" className="">
									(705) 328-3635
								</a>{" "}
								or visit our website to learn more about our services and
								schedule an appointment.
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default RustProofing;
