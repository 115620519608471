import React from "react";
import { services } from "./sub-components/services";
import ServiceButton from "./sub-components/ServiceButton";
import ServiceCard from "./sub-components/ServiceCard";

const ServiceSection = () => {
	return (
		<section className="page-section bg-light" id="service">
			<div className="container">
				<div className="text-center">
					<h2 className="section-heading">How Can We Help?</h2>
				</div>
				<div className="row" id="servicesContainer">
					{services.slice(0, 4).map((service) => (
						<ServiceCard
							key={service.name}
							name={service.name}
							href={service.href}
							price={service.price}
						/>
					))}
				</div>
				<div className="mt-5" id="servicesButton">
					<ServiceButton />
				</div>
			</div>
		</section>
	);
};

export default ServiceSection;
