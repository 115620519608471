import React from "react";
import { Helmet } from "react-helmet";

const Brakes = () => {
	return (
		<>
			<Helmet>
				<meta
					name="description"
					content="T&B Automotive in Lindsay, Ontario offers professional brake inspections for optimal vehicle safety. Ensure your brakes are in top condition with our experienced technicians. Book your brake inspection today!"
				/>
				<title>Brake Inspections in Lindsay, Ontario | T&B Automotive</title>
			</Helmet>
			<section className="page-section bg-light" id="service">
				<div className="container">
					<div className="text-center">
						<h1 className="section-heading text-uppercase">
							Brake Inspections in Lindsay, Ontario
						</h1>
						<p className="service-caption">
							Welcome to T&B Automotive, your trusted destination for
							comprehensive brake inspections in Lindsay, Ontario. Our skilled
							technicians are committed to ensuring your vehicle's safety by
							providing thorough brake inspections and maintenance services.
							With our expertise and attention to detail, you can drive with
							confidence knowing your brakes are in top condition.
						</p>
						<div className="ps-3 bg- border-start border-5 border-primary">
							<h2 className="mb-2 text-start">Why Brake Inspections Matter</h2>
							<p className="fs-5 text-start mb-4">
								Brakes are a crucial component of your vehicle's safety system,
								and regular brake inspections are essential to detect potential
								issues before they turn into major problems. At T&B Automotive,
								we understand the importance of reliable brakes in preventing
								accidents and ensuring smooth operation on the roads of Lindsay,
								Ontario.
							</p>
						</div>
						<h3 className="mb-4">Our Brake Inspection Process</h3>
						<p className="fs-5">
							When you bring your vehicle to T&B Automotive for a brake
							inspection, our knowledgeable technicians will conduct a
							comprehensive assessment to evaluate the condition of your braking
							system. Our process includes:
						</p>
						<div
							className="accordion col-md-6 mx-auto mb-3"
							id="serviceAccordion"
						>
							{/* Item #1 */}
							<div className="accordion-item">
								<h3 className="accordion-header">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseOne"
										aria-expanded="true"
										aria-controls="collapseOne"
									>
										Visual Inspection
									</button>
								</h3>
								<div
									id="collapseOne"
									class="accordion-collapse collapse show"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We visually inspect all brake components, including brake
										pads, rotors, calipers, brake lines, and hoses, to check for
										signs of wear, damage, or fluid leaks.
									</div>
								</div>
							</div>
							{/* Item #2 */}
							<div class="accordion-item">
								<h3 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseTwo"
										aria-expanded="false"
										aria-controls="collapseTwo"
									>
										Measurement and Testing
									</button>
								</h3>
								<div
									id="collapseTwo"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										Our technicians utilize advanced tools and equipment to
										measure the thickness of brake pads and evaluate rotor
										condition. We also conduct brake performance tests to ensure
										optimal braking efficiency.
									</div>
								</div>
							</div>
							{/* Item #3 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseThree"
										aria-expanded="false"
										aria-controls="collapseThree"
									>
										Fluid Examination
									</button>
								</h2>
								<div
									id="collapseThree"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We inspect the brake fluid level and quality, ensuring it
										meets the manufacturer's specifications. If necessary, we
										can perform a brake fluid flush and replace it with fresh
										fluid for optimal performance.
									</div>
								</div>
							</div>
							{/* Item #4 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFour"
										aria-expanded="false"
										aria-controls="collapseFour"
									>
										Recommendations and Maintenance
									</button>
								</h2>
								<div
									id="collapseFour"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										After completing the inspection, our experts will provide a
										detailed report of our findings. If any issues or potential
										concerns are detected, we will offer suitable
										recommendations and perform the necessary brake repairs or
										replacements to ensure your safety on the road.
									</div>
								</div>
							</div>
						</div>
						<div className="service-caption">
							<p className="fs-4 fw-semibold">
								Schedule Your Brake Inspection Today!
							</p>
							<p className="col-6 mx-auto p-3 border-start border-5 border-primary">
								<strong>
									Contact us today at{" "}
									<a href="tel:17053283635" className="">
										(705) 328-3635
									</a>{" "}
									or <a href="mailto: info@tandbauto.ca">info@tandbauto.ca</a>{" "}
									to schedule an appointment or inquire about our brake
									inspection services.
								</strong>
							</p>
							<p className="fs-5">
								{" "}
								Don't compromise on your vehicle's safety. Schedule a brake
								inspection with T&B Automotive in Lindsay, Ontario, today. Our
								friendly staff is ready to assist you and provide top-notch
								service to keep your brakes in optimal condition. Contact us now
								to book an appointment or to learn more about our brake
								inspection services.
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Brakes;
