import React from "react";

const Footer = () => {
	const year = new Date().getFullYear();

	return (
		<footer className="footer py-4">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-4 text-lg-start">
						Copyright &copy; T&B Automotive {year}
					</div>
					<div className="col-lg-4 my-3 my-lg-0">
						{/* <a
							className="btn btn-dark btn-social mx-2"
							href="#!"
							aria-label="Facebook"
						>
							<i className="bi bi-facebook"></i>
						</a> */}
					</div>
					<div className="col-lg-4 text-lg-end">
						<a className="link-dark text-decoration-none me-3" href="#!">
							Privacy Policy
						</a>
						<a className="link-dark text-decoration-none" href="#!">
							Terms of Use
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
