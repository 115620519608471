import React from "react";
import logo from "../../logo.svg";
import NavItem from "./sub-components/NavItem";

const Navigation = () => {
	return (
		<div className="bg-light border-bottom border-3 border-dark">
			<div className="mx-3">
				<nav className="navbar navbar-expand-lg">
					<a className="navbar-brand" href="/">
						<img src={logo} alt="T&B Automotive" height={"100vh"} />
					</a>
					<button
						className="navbar-toggler btn btn-primary"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarNav"
						aria-controls="navbarNav"
						aria-expanded="false"
						aria-label="Toggle Navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<NavItem />
				</nav>
			</div>
		</div>
	);
};

export default Navigation;
