import Header from "./components/Header";
import ServiceSection from "./components/ServiceSection";
import ContactForm from "./components/ContactForm";

const Homepage = () => {
	return (
		<div className="min-vh-100">
			<Header />
			<ServiceSection />
			<ContactForm />
		</div>
	);
};

export default Homepage;
