import React from "react";

const BusinessHours = () => {
	return (
		<div className="container">
			<div className="text-center">
				<h5>Business Hours</h5>
				<p>
					Mon - Fri: 8:00 AM - 5:30 PM
					<br />
					Sat - Sun: Closed
				</p>
			</div>
		</div>
	);
};

export default BusinessHours;
