const AboutUs = () => {
	return (
		<section className="page-section">
			<div className="min-vh-100">
				<div className="container">
					<div className="text-center">
						<h2 className="section-heading text-uppercase">About Us</h2>
						<p className="fs-4">
							<strong>T&B Automotive</strong>, located in Lindsay, Ontario has
							been serving our loyal customers for over 15 years. As a boutique
							shop we serve our clients with the utmost care and a personal
							touch. Our large bays allow for any size of personal vehicle to be
							serviced. We always provide upfront pricing before beginning any
							project so you will always know what to expect from the
							professional team and T&B automotive.
						</p>
						<hr />
						<p className="fs-4">
							If you have any questions on how we can help keep your vehicle on
							the road and operating safely give us a call! We're always happy
							to help.
						</p>
						<p className="col-6 mx-auto p-3 border-start border-5 border-primary">
							<strong>
								Contact us today at{" "}
								<a
									href="tel:17053283635"
									className=""
									style={{ color: "#004aad" }}
								>
									(705) 328-3635
								</a>{" "}
								or{" "}
								<a
									href="mailto: info@tandbauto.ca"
									style={{ color: "#004aad" }}
								>
									info@tandbauto.ca
								</a>{" "}
								to schedule an appointment or inquire about our services.
							</strong>
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutUs;
