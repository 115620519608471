import ContactForm from "./components/ContactForm";

const ContactUs = () => {
	return (
		<div className="min-vh-100">
			<ContactForm />
		</div>
	);
};

export default ContactUs;
