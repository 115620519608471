import React from "react";
import logo from "../../../assets/logos/logo375.png";

const HomeLogo = () => {
	return (
		<div>
			<div className="text-center">
				<a href="/">
					<img src={logo} alt="" className="w-50" />
				</a>
			</div>
		</div>
	);
};

export default HomeLogo;
