import React from "react";
import { Helmet } from "react-helmet";

const TireChange = () => {
	return (
		<>
			<Helmet>
				<meta
					name="description"
					content="Looking for professional tire changes in Lindsay, Ontario? T&B
					Automotive offers expert tire change services to ensure your vehicle's
					safety and performance. Contact us today!"
				/>
				<title>Tire Changes in Lindsay, Ontario | T&B Automotive</title>
			</Helmet>
			<section className="page-section bg-light" id="service">
				<div className="container">
					<div className="text-center">
						<h1 className="section-heading text-uppercase">
							Expert Tire Changes in Lindsay, Ontario
						</h1>
						<p className="service-caption">
							At T&B Automotive, we understand the importance of maintaining
							proper tire health for your vehicle's safety and performance.
							Located in Lindsay, Ontario, we offer professional tire change
							services to keep your vehicle running smoothly on the road. Our
							team of skilled technicians is committed to delivering exceptional
							customer service and ensuring your tires are in top condition.
						</p>
						<h2 className="mb-5">
							<strong>Why Choose T&B Automotive for Your Tire Changes?</strong>
						</h2>
						<div
							className="accordion col-md-6 mx-auto mb-3"
							id="serviceAccordion"
						>
							{/* Item #1 */}
							<div className="accordion-item">
								<h3 className="accordion-header">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseOne"
										aria-expanded="true"
										aria-controls="collapseOne"
									>
										Reliable Tire Change Services
									</button>
								</h3>
								<div
									id="collapseOne"
									class="accordion-collapse collapse show"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										Our experienced technicians at T&B Automotive have extensive
										knowledge and expertise in handling tire changes for all
										types of vehicles. Whether you drive a car, truck, SUV, or
										van, we have you covered. We use advanced equipment and
										follow industry best practices to ensure accurate and
										efficient tire changes, giving you peace of mind on the
										road.
									</div>
								</div>
							</div>
							{/* Item #2 */}
							<div class="accordion-item">
								<h3 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseTwo"
										aria-expanded="false"
										aria-controls="collapseTwo"
									>
										Wide Selection of Quality Tires
									</button>
								</h3>
								<div
									id="collapseTwo"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										To provide you with the best options, we offer a wide range
										of high-quality tires from reputable brands. Our team can
										help you select the right tires that suit your driving
										needs, preferences, and budget. We prioritize safety and
										durability, ensuring you get the best value for your
										investment.
									</div>
								</div>
							</div>
							{/* Item #3 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseThree"
										aria-expanded="false"
										aria-controls="collapseThree"
									>
										Competitive Pricing
									</button>
								</h2>
								<div
									id="collapseThree"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										At T&B Automotive, we believe in offering fair and
										transparent pricing for our tire change services. We provide
										detailed cost estimates upfront, without any hidden charges
										or surprises. Rest assured that you'll receive top-notch
										service at a competitive price, backed by our commitment to
										customer satisfaction.
									</div>
								</div>
							</div>
							{/* Item #4 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFour"
										aria-expanded="false"
										aria-controls="collapseFour"
									>
										Timely and Efficient Service
									</button>
								</h2>
								<div
									id="collapseFour"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We understand that your time is valuable, and we strive to
										complete our tire change services promptly without
										compromising quality. Our team works diligently to ensure
										minimal waiting time, allowing you to get back on the road
										as quickly as possible.
									</div>
								</div>
							</div>
							{/* Item #5 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFive"
										aria-expanded="false"
										aria-controls="collapseFive"
									>
										Customer Satisfaction Guarantee
									</button>
								</h2>
								<div
									id="collapseFive"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										Your satisfaction is our priority. We take pride in
										delivering exceptional customer service and ensuring your
										complete satisfaction with our tire change services. Our
										friendly and knowledgeable staff are always ready to address
										any questions or concerns you may have.
									</div>
								</div>
							</div>
						</div>
						<div className="service-caption">
							<p className="fs-5">
								When it comes to reliable and expert tire changes in Lindsay,
								Ontario, trust the team at T&B Automotive. We are dedicated to
								providing you with exceptional service, quality tires, and
								competitive prices.
							</p>
							<p className="col-6 mx-auto p-3 border-start border-5 border-primary">
								<strong>
									Contact us today at{" "}
									<a href="tel:17053283635" className="">
										(705) 328-3635
									</a>{" "}
									or <a href="mailto: info@tandbauto.ca">info@tandbauto.ca</a>{" "}
									to schedule an appointment or inquire about our tire change
									services.
								</strong>
							</p>
							<p className="fs-5">
								{" "}
								Drive with confidence knowing your vehicle is equipped with
								properly installed and maintained tires from T&B Automotive.
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default TireChange;
