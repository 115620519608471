import React from "react";

const ContactMap = () => {
	const openMaps = () => {
		window.open(
			"https://www.google.com/maps/dir//T+%26+B+Automotive/@44.3602845,-78.7960542,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4cd52eeec9b51e89:0xe5cfe27b36992204!2m2!1d-78.7260178!2d44.3603137"
		);
	};
	return (
		<div className="container">
			<div className="text-center">
				<h5>Address</h5>
				<button className="btn btn-xl btn-primary" onClick={openMaps}>
					Get Directions{" "}
					<span>
						<i className="bi bi-geo-alt-fill"></i>
					</span>
				</button>
				<p className="my-2">
					118 Queen Street
					<br />
					Lindsay, ON
					<br />
					K9V 1G9
					<br />
					Canada
				</p>
			</div>
		</div>
	);
};

export default ContactMap;
