import React from "react";
import { Helmet } from "react-helmet";

const Exhaust = () => {
	return (
		<>
			<Helmet>
				<meta
					name="description"
					content="T&B Automotive offers top-quality auto exhaust repair services in Lindsay, Ontario. Our expert technicians provide comprehensive solutions to fix exhaust system issues efficiently. Contact us today for reliable and affordable exhaust repairs."
				/>
				<title>Auto Exhaust Repairs in Lindsay, Ontario | T&B Automotive</title>
			</Helmet>
			<section className="page-section bg-light" id="service">
				<div className="container">
					<div className="text-center">
						<h1 className="section-heading text-uppercase">
							Auto Exhaust Repairs in Lindsay, Ontario
						</h1>
						<p className="service-caption">
							Welcome to T&B Automotive, your trusted destination for auto
							exhaust repairs in Lindsay, Ontario. If you're experiencing issues
							with your vehicle's exhaust system, our skilled technicians are
							here to help. With years of experience and a commitment to
							exceptional service, we strive to deliver reliable and affordable
							solutions for all your exhaust repair needs.
						</p>
						<h3 className="mb-4">
							Why Choose T&B Automotive for Auto Exhaust Repairs?
						</h3>
						<div
							className="accordion col-md-6 mx-auto mb-3"
							id="serviceAccordion"
						>
							{/* Item #1 */}
							<div className="accordion-item">
								<h3 className="accordion-header">
									<button
										className="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseOne"
										aria-expanded="true"
										aria-controls="collapseOne"
									>
										Experienced Technicians
									</button>
								</h3>
								<div
									id="collapseOne"
									class="accordion-collapse collapse show"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										Our team consists of highly trained technicians with
										expertise in diagnosing and repairing exhaust system
										problems. We have the knowledge and skills to handle a wide
										range of exhaust issues.
									</div>
								</div>
							</div>
							{/* Item #2 */}
							<div class="accordion-item">
								<h3 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseTwo"
										aria-expanded="false"
										aria-controls="collapseTwo"
									>
										Quality Repairs
									</button>
								</h3>
								<div
									id="collapseTwo"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										At T&B Automotive, we prioritize quality and aim to provide
										lasting repairs. We use advanced diagnostic tools and
										high-quality replacement parts to ensure your exhaust system
										functions optimally.
									</div>
								</div>
							</div>
							{/* Item #3 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseThree"
										aria-expanded="false"
										aria-controls="collapseThree"
									>
										Comprehensive Services
									</button>
								</h2>
								<div
									id="collapseThree"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										From minor leaks to complete exhaust system replacements, we
										offer comprehensive services to address any exhaust-related
										problem. Our technicians will thoroughly inspect your
										vehicle, identify the issue, and recommend the most suitable
										repair options.
									</div>
								</div>
							</div>
							{/* Item #4 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFour"
										aria-expanded="false"
										aria-controls="collapseFour"
									>
										Competitive Pricing
									</button>
								</h2>
								<div
									id="collapseFour"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										We understand that auto repairs can be costly. That's why we
										offer competitive pricing for our exhaust repair services.
										We strive to deliver affordable solutions without
										compromising on the quality of our work.
									</div>
								</div>
							</div>
							{/* Item #5 */}
							<div class="accordion-item">
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#collapseFive"
										aria-expanded="false"
										aria-controls="collapseFive"
									>
										Customer Satisfaction
									</button>
								</h2>
								<div
									id="collapseFive"
									class="accordion-collapse collapse"
									data-bs-parent="#serviceAccordion"
								>
									<div class="accordion-body">
										Your satisfaction is our top priority. We are dedicated to
										delivering excellent customer service and ensuring that you
										have a positive experience at our facility. We'll keep you
										informed throughout the repair process and answer any
										questions you may have.
									</div>
								</div>
							</div>
						</div>
						<div>
							<h3>Common Signs of Exhaust System Issues:</h3>
							<ul className="list-group col-6 mx-auto">
								<li className="list-group-item">
									<p>Loud or unusual noises coming from the exhaust</p>
								</li>
								<li className="list-group-item">
									<p>Decreased fuel efficiency</p>
								</li>
								<li className="list-group-item">
									<p>Vibrations or excessive shaking of the vehicle</p>
								</li>
								<li className="list-group-item">
									<p>Rotten egg smell</p>
								</li>
								<li className="list-group-item">
									<p>Engine misfires or loss of power</p>
								</li>
								<li className="list-group-item">
									<p>Visible smoke or fumes from the exhaust pipe</p>
								</li>
							</ul>
						</div>
						<div className="service-caption">
							<p className="col-6 mx-auto p-3 border-start border-5 border-primary">
								<strong>
									Contact us today at{" "}
									<a href="tel:17053283635" className="">
										(705) 328-3635
									</a>{" "}
									or <a href="mailto: info@tandbauto.ca">info@tandbauto.ca</a>{" "}
									to schedule an appointment or inquire about our exhaust
									services.
								</strong>
							</p>
							<p className="fs-5">
								{" "}
								When it comes to reliable and efficient auto exhaust repairs in
								Lindsay, Ontario, T&B Automotive is the name you can trust. Our
								skilled technicians are ready to diagnose and fix any issues
								with your vehicle's exhaust system. Don't let exhaust problems
								compromise your driving experience or vehicle performance.
								Experience our top-quality service and let us help you get your
								vehicle back on the road in optimal condition.
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Exhaust;
