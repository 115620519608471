import React from "react";

const ServiceCard = ({ name, href, price }) => {
	return (
		<div className="col-lg-6" key={name}>
			<a className="service-link" href={`/${href}`}>
				<div className="service-item">
					<div className="service-caption">
						<div className="service-caption-heading">{name}</div>
						<div className="service-caption-subheading">
							From ${price.toFixed(2)}
						</div>
					</div>
				</div>
			</a>
		</div>
	);
};

export default ServiceCard;
