import React from "react";

const Header = () => {
	return (
		<header className="masthead">
			<div className="container">
				<div className="masthead-heading text-uppercase">T&B Automotive</div>
				<div className="masthead-subheading">Auto Repair Done Right</div>
				<a href="/contact-us" className="btn btn-primary btn-xl text-uppercase">
					Contact Us
				</a>
			</div>
		</header>
	);
};

export default Header;
